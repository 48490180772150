<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑礼品' :'创建礼品'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 名称 -->
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
        />
      </a-form-item>
      <!-- 顺序 -->
      <a-form-item
        label="顺序"
        name="sort"
        :rules="[{ required: true, validator: validator_sort }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.sort"
        />
      </a-form-item>
      <!-- 兑换价格 -->
      <a-form-item
        label="兑换价格"
        name="money"
        :rules="[{ required: true, validator: validator_sort }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.money"
          suffix="金币"
        />
      </a-form-item>
      <!-- 礼品类型 -->
      <a-form-item
        label="礼品类型"
        name="gift_type"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          v-model:value="formState.gift_type"
          placeholder="请选择"
          @change="changeGiftType"
        >
          <a-select-option
            v-for="(item, index) in GiftTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 会员天数 -->
      <a-form-item
        v-if="formState.gift_type === 1"
        label="会员天数"
        name="gift_content"
        :rules="[{ required: true, validator: validator_sort }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.gift_content"
          suffix="天"
        />
      </a-form-item>
      <!-- 供应商商品ID -->
      <a-form-item
        v-if="formState.gift_type === 2"
        label="供应商商品ID"
        name="gift_content"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.gift_content"
        />
      </a-form-item>
      <!-- 礼品图片 -->
      <a-form-item
        label="礼品图片"
        name="cover_urls"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="NewFilmDrawer"
          :fileNumber="1"
          :imgSizeMode="3"
          :imgSizeWidth="208"
          :imgSizeHeight="208"
          imgSizeError="图片尺寸为 208 * 208"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'cover_urls') }"
          :fileList="formState.cover_urls"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸为 208x208</div>
        <div class="form-upload-list" v-if="formState.cover_urls.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.cover_urls"
            :key="index"
          >
            <ImageView
              class="upload-image"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeletecover_url('cover_urls', index)"
            >
            </ImageView>
          </div>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Upload from '@/components/Upload'
import Loading from '@/components/Loading'
import ImageView from '@/components/ImageView'
import { giftSave } from '@/api/copyright'
import { GiftTypes } from '@/utils/constantList'
import Pub from '@/utils/public'
import dayjs from 'dayjs'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 详情
let detail = ref({})
// 表单
let formState = reactive({
  // 名称
  name: undefined,
  // 顺序
  sort: undefined,
  // 价格
  money: undefined,
  // 礼品类型
  gift_type: undefined,
  // 礼品详情
  gift_content: undefined,
  // banner图
  cover_urls: [],
  cover_url: undefined
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  detail.value = params
  visible.value = true
  // 赋值
  nextTick(() => {
    // 获取选择项
    getSelectList()
    if (params) {
      // id
      id.value = params.id
      formState.name = params.name
      formState.sort = params.sort
      formState.money = params.money
      formState.gift_content = params.gift_content
      formState.gift_type = params.gift_type
      if (params.cover_url) {
        formState.cover_urls = [{
          url: params.cover_url
        }]
        formState.cover_url = params.cover_url
      } else {
        formState.cover_urls = []
        formState.cover_url = undefined
      }
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 选择礼品类型
function changeGiftType (value) {
  formState.gift_content = undefined
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 准备上传（图片）
function beforeUploadPro (file, key) {
  if (!file.type.includes('image')) {
    message.error('请上传正确的图片类型文件')
    return false
  }
  // 清空列表
  if (key) { formState[key] = [] }
  // 允许
  return true
}

// 移除封面
function touchDeletecover_url (cover_urlKey, index) {
  formState[cover_urlKey].splice(index, 1)
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    formState.cover_url = formState.cover_urls[0].url
    const params = {
      id: id.value,
      ...formState,
      cover_url: formState.cover_urls[0].url
    }
    giftSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        if (id.value) {
          message.success('更新成功')
        } else {
          message.success('创建成功')
        }
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 校验顺序数量
function validator_sort (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}

// 校验价格
function validator_money (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  }
  return Promise.resolve()
}

// 获取选择项
function getSelectList () {
  // 默认模版
  // chargeAll().then(res => {
  //   const { code, data } = res
  //   if (code === 0) {
  //     defaultList.value = data
  //   }
  // })
}


// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-input {
  width: 70%;
}
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover_url;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  width: 208px;
  height: 208px;
}
.desc-textarea {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}
.sync-tt {
  padding-top: 6px;
}
.recharge-delete {
  margin-left: 12px;
  white-space: nowrap;
}
</style>