<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>项目配置</a-breadcrumb-item>
    <a-breadcrumb-item>兑换礼品管理</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="add()"><plus-outlined />添加礼品</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 上架状态 -->
      <template v-if="column.key === 'status'">
        <a-switch
          v-model:checked="record.status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChange($event, record)"
        />
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="add(record)">编辑</a>
        <!-- 上移：第一组禁用 -->
        <a-button
          type="link"
          :disabled="index === 0"
          @click="handleSort(record, 'up')"
        >
          上移
        </a-button>
        <!-- 下移：最后一组禁用 -->
        <a-button
          type="link"
          :disabled="index === dataSource.length - 1"
          @click="handleSort(record, 'down')"
        >
          下移
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑项目设置 -->
  <Add ref="RefAdd" @success="getList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Add from './components-gift-management/Add'
import { message } from 'ant-design-vue'
import { ProjectAttrs } from '@/utils/constantList'
import { giftList, giftSort, giftStatus } from '@/api/copyright'

// 新增、编辑组件实例
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 搜索
let search = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 200,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '顺序',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '礼品名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '兑换价格',
    dataIndex: 'money',
    key: 'money'
  },
  {
    title: '上架状态',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: '兑换成功数',
    dataIndex: 'conversion_count',
    key: 'conversion_count'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取项目列表
  getList()
})

// 获取项目列表
function getList () {
  isLoading.value = true
  const params = {
    kw: search.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  giftList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        item.status = Boolean(item.status)
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 新增、编辑项目设置
function add (record) {
  RefAdd.value.showDrawer(record || {})
}

// 开发设置
function develop (record) {
  // RefDeveloper.value.showDrawer(record)
}

// // 查询
// function handleSearch () {
//   pagination.current = 1
//   // 获取列表
//   getList()
// }

// // 重置
// function handleReset () {
//   // 筛选项重置
//   // 分页重置
//   pagination.current = 1
//   getList()
// }

// 切换启用状态
function statusChange (e, record) {
  isLoading.value = true
  const params = {
    id: record.id,
    status: Number(e)
  }
  giftStatus(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getList()
  }).catch(() => {
    // isLoading.value = false
    getList()
  })
}

// 排序板块
function handleSort (record, type) {
  isLoading.value = true
  const params = {
    id: record.id,
    type
  }
  giftSort(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getList()
    } else {
      message.error(res.message || msg)
    }
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 20px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>